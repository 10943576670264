import React from 'react';

import Layout from '../components/Layout';

import image0 from '../assets/images/news/thumb-0.jpg';
import image1 from '../assets/images/news/thumb-1.jpg';
import image2 from '../assets/images/news/thumb-2.jpg';
import image3 from '../assets/images/news/thumb-3.jpg';
import image4 from '../assets/images/news/thumb-4.jpg';
import image5 from '../assets/images/news/thumb-5.jpg';
import image6 from '../assets/images/news/thumb-6.jpg';
import image7 from '../assets/images/news/thumb-7.jpg';
import image8 from '../assets/images/news/thumb-8.jpg';
import image9 from '../assets/images/news/thumb-9.jpg';
import blog0 from '../assets/images/news/blog-thumb-0.jpg';
import blog1 from '../assets/images/news/blog-thumb-1.jpg';
import blog2 from '../assets/images/news/blog-thumb-2.jpg';
import leadNewsImage from '../assets/images/news/lead-news-0.jpg';

const Blog = ({ path }) => {
  const leadNews = {
    img: leadNewsImage,
    date: 'MAY 12, 2022',
    title: 'LawAdvisor Secures Backing from Legal Heavyweights with Seed Round ',
    description: 'Legaltech specialist’s investors include leading legal and technology innovators from Silicon Valley, including Microsoft and Gilead Sciences. Creates advisory panel to help scale and seize opportunities.',
    url: 'https://lawadvisor.ventures/news/lawadvisor-secures-backing-from-legal-heavyweights-with-seed-round',
  };

  const news = [
    {
      img: image9,
      date: 'MAY 12, 2022',
      title: 'LawAdvisor Raises $5M Investment, With Former Orrick CEO, Silicon Valley Funders\' Support',
      description: 'LawAdvisor, which released two products in 2021 designed with input from Barclays and a host of law firms, will bring former Orrick CEO and chairman Ralph Baxter and other investors on as advisers.',
      url: 'https://www.law.com/legaltechnews/2022/05/11/lawadvisor-raises-5-million-investment-with-former-orrick-ceo-silicon-valley-funders-support/',
    },
    {
      img: image8,
      date: 'MAY 12, 2022',
      title: 'Melbourne-founded LawAdvisor secures $7m in backing after raising the bar with new technology',
      description: 'Melbourne-founded Legal-tech startup LawAdvisor Ventures has raised $7.18 million from backers, including Google Maps founder Lars Rasmussen...',
      url: 'https://www.businessnewsaustralia.com/articles/lawadvisor-secures--7m-in-backing-after-raising-the-bar-with-new-technology.html',
    },
    {
      img: image7,
      date: 'MAY 12, 2022',
      title: '“Google for law” startup LawAdvisor scores $5m investment',
      description: 'LawAdvisor is the latest startup aiming to revolutionise legal practices, and with $5m new investment underneath its belt, it has just strengthened its ability to smash efficiency roadblocks for lawyers.',
      url: 'https://www.verdict.co.uk/google-for-law-startup-lawadvisor-scores-5m-investment/',
    },
    {
      img: image6,
      date: 'MAY 12, 2022',
      title: 'LawAdvisor raises $5m seed funding from heavyweight investors',
      description: 'Melbourne-founded legaltech startup LawAdvisor Ventures has raised USD$5m of investment in a seed round involving heavyweight investors from the likes of Microsoft and Gilead Sciences, as well as Google Maps founder Lars Rasmussen.',
      url: 'https://legaltechnology.com/2022/05/12/lawadvisor-raises-5m-seed-funding-from-heavyweight-investors/',
    },
    {
      img: image5,
      date: 'MAY 12, 2022',
      title: 'Ex-Orrick CEO Joins Seed Round Funding For Legal Tech Co.',
      description: 'A group of legal industry giants, including the former chairman and CEO of Orrick Herrington & Sutcliffe LLP, has backed legal technology software company LawAdvisor Ventures in the form of a...',
      url: 'https://www.law360.com/pulse/amp/articles/1492636',
    },
    {
      img: image4,
      date: 'MAY 12, 2022',
      title: 'Lawtech start-ups bag multi-million pound investments',
      description: 'LawAdvisor Ventures, a lawtech start-up with software platforms aimed at corporate in-house legal teams has raised $5m (£4m) from big-name Silicon Valley backers associated with Google, Facebook and Microsoft.',
      url: 'https://www.legalfutures.co.uk/latest-news/lawtech-start-ups-bag-multi-million-pound-investments',
    },
    {
      img: image3,
      date: 'MAY 12, 2022',
      title: 'Big investors inject US$5m into Melbourne-born legaltech startup',
      description: 'A group of major investors have invested US$5m into Melbourne-founded legaltech company LawAdvisor Ventures in a seed round.',
      url: 'https://www.thelawyermag.com/au/news/general/big-investors-inject-us5m-into-melbourne-born-legaltech-startup/405702',
    },
    {
      img: image2,
      date: 'MAY 12, 2022',
      title: 'Is this the start of something big in Lawtech',
      description: 'London headquartered Lawtech firm LawAdvisor Ventures has raised US$5 million in a seed round led by some significant industry angel investors. The funding will help the company scale and further accelerate development in its product portfolio, helping to change how law is practised.',
      url: 'https://www.enterprisetimes.co.uk/2022/05/12/is-this-the-start-of-something-big-in-lawtech/',
    },
    {
      img: image1,
      date: 'MAY 12, 2022',
      title: 'Google Map founder backs LawAdvisor in $5M round to transform legal service delivery',
      description: 'LawAdvisor Venture, a London-based legal technology platform, announced on Thursday that it has secured $5M funding in the Seed round from Ralph Baxter, Ben Davey, Jason Barnwell, Gary Tully, and Lars Rasmussen, founder of Google Maps.',
      url: 'https://techfundingnews.com/google-map-founder-backs-lawadvisor-in-5m-round-to-transform-legal-service-delivery/',
    },
    {
      img: image0,
      date: 'JANUARY 27, 2021',
      title: 'LawAdvisor launches new solutions in collaboration with Barclays and leading law firms to revolutionise the practice of law',
      description: 'Today legal technology specialist LawAdvisor, has launched two innovative new legal technology solutions, aimed at transforming the way law firms work with corporate in-house legal departments and legal matters are managed.',
      url: 'https://lawadvisor.ventures/news/lawadvisor-launches-new-solutions-in-collaboration-with-barclays/',
    },
    {
      img: blog2,
      date: null,
      title: 'Transforming multi-jurisdictional litigation with Bird & Bird',
      description: 'In 2019, Bird & Bird, one of the world&apos;s leading international intellectual property firms, wanted a better and more efficient way to manage large-scale multi-jurisdictional patent litigation. Such complex, truly global mandates can involve multiple counterparties, multiple...',
      url: '/blog/transforming-multi-jurisdictional-litigation-with-bird-and-bird',
    },
    {
      img: blog1,
      date: null,
      title: 'From the Legal Project Manager’s mouth: Helga Butcher’s LPM journey at Barclays',
      description: 'Whilst navigating the unprecedented effects that Covid-19 has had on businesses globally, scrutiny over expenditure has never been more rigorous. Consequently, we are seeing increased pressure on in-house teams to be more efficient and cost-effective, particularly when...',
      url: '/blog/from-the-legal-project-managers-mouth',
    },
    {
      img: blog0,
      date: null,
      title: 'Fibonacci: the Golden Standard for Legal Project Management',
      description: 'Fibonacci is a built-for-purpose legal project management platform that has been designed and developed in partnership with a panel of innovative law firms and in-house corporate legal teams.',
      url: '/blog/fibonacci-the-golden-standard-for-legal-project-management',
    },
  ];

  return (
    <Layout
      pageName="resources"
      title="News - Fibonacci"
      path={path}
      image="/metaimages/resources.jpg"
      description="Fibonacci is the first built for purpose legal project management platform that gives you a 360° view on where work stands without bloated spreadsheets and endless emails."
    >
      <div className="banner -resources">
        <div className="chip">NEWS</div>
        <h1>Knowledge is power</h1>
        <p>
          Expert insights to power your practice.
        </p>
      </div>
      <div className="resource-container">
        <div className="content fit-width">
          <div className="lead-news">
            <div className="thumbnail">
              <a href={leadNews.url}>
                <img src={leadNews.img} alt="lead-news" />
              </a>
            </div>
            <div className="details">
              <span className="date">{leadNews.date}</span>
              <a href={leadNews.url} className="lead-news">
                <h3 className="title">{leadNews.title}</h3>
                <p className="description">{leadNews.description}</p>
              </a>
            </div>
          </div>
          <div className="latest-news">
            <div className="list">
              {news.map((d) => (
                <div key={d.title} className="news-card">
                  <a target="_blank" className="link" rel="noreferrer" href={d.url}>
                    <img className="thumbnail" src={d.img} alt={d.title} />
                    {
                      d.date
                        && <span className="date">{d.date}</span>
                    }
                    <h5 className="title">{d.title}</h5>
                    <p className="description">{d.description}</p>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Blog;
